.tracibility-active article {
  animation: appear 700ms ease-in-out 0ms forwards;
}
.tracibility-active article:nth-child(2) {
  animation: appear 700ms ease-in-out 100ms forwards;
}
.tracibility-active article:nth-child(3) {
  animation: appear 700ms ease-in-out 200ms forwards;
}
.tracibility-active article:nth-child(4) {
  animation: appear 700ms ease-in-out 300ms forwards;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(16px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
