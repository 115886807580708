.slide {
  position: absolute;
  left: 15px;
  right: 15px;
}

.slide-enter {
  opacity: 0;
  transform: scale(0.9);
}

.slide-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.slide-exit {
  opacity: 1;
  transform: scale(1);
}

.slide-exit-active {
  opacity: 0;
  transform: scale(0.9);

  transition: opacity 300ms, transform 300ms;
}

.ecosystem .swiper-slide {
  height: auto !important;
}

/* !Swiper for home page */
.home-swiper-container .swiper-pagination-bullet {
  transition: all !important;
  transition-duration: 1s !important;
  width: 0.75em !important;
  height: 0.75em !important;
  background: #193C34;
}

.home-swiper-container .swiper-pagination-bullet-active {
  width: 2.8rem !important;
  border-radius: 0.5em !important;

}

.ecosystem .swiper-pagination-bullet {
  background-color: #E8BA3A !important;
}

/* !Clients section */
.home-clients .marquee-home {
  animation: marquee-home 45s linear infinite;
}

.home-clients .clients-gradient {
  background: linear-gradient(90deg, #ebede2 0%, rgba(255, 253, 227, 0) 13%, rgba(255, 253, 225, 0) 87%, #ebede2 100%);
}

@media (min-width: 1280px) {
  .home-clients .marquee-home {
    animation: marquee-home 55s linear infinite;
  }
}

@keyframes marquee-home {
  to {
    transform: translate(calc(-50% - 1rem));
  }
}

@media (max-width: 768px) {
  .home-swiper-container .swiper-slide {
    opacity: 0 !important;
  }

  .home-swiper-container .swiper-slide-active,
  .home-swiper-container .swiper-slide-visible {
    opacity: 1 !important;
  }

  .home-swiper-container .swiper-slide-prev,
  .home-swiper-container .swiper-slide-next {
    opacity: 0 !important;
  }

  .home-swiper-container .swiper-pagination-bullet {
    transform: translateY(3rem);
  }
}

@media (min-width: 767px) {
  .ecosystem .swiper-pagination {
    position: absolute !important;
    transform: rotateZ(90deg) translateY(-50%);
    height: max-content !important;
    width: max-content !important;
    top: 50% !important;
    left: auto !important;
    right: -7% !important;
    z-index: 100;
    /* right: 0% !important; */
  }
}

@media (min-width: 1024px) {
  .home-swiper-container .swiper-pagination {
    position: absolute !important;
    transform: rotateZ(90deg) translateY(-50%);
    height: max-content !important;
    width: max-content !important;
    top: 50% !important;
    left: auto !important;
    right: -3% !important;
  }
}

.technology-desc-scrollbar::-webkit-scrollbar-track {
  background-color: rgba(22, 62, 51, 0.1);
}