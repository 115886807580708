.animate-words {
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(0);
  }

  15% {
    transform: translateY(-70px);
  }

  35% {
    transform: translateY(-70px);
  }

  40% {
    transform: translateY(-140px);
  }

  60% {
    transform: translateY(-140px);
  }

  65% {
    transform: translateY(-210px);
  }

  85% {
    transform: translateY(-210px);
  }

  90% {
    transform: translateY(-280px);
  }

  100% {
    transform: translateY(-280px);
  }
}

.clipImage {
  clip-path: circle(0px at center);
  transition: clip-path 2s ease-in-out;
}

.remove-clip {
  clip-path: circle(1000px at center);
}

.marquee-aboutUs {
  animation: marquee-aboutUs 40s linear infinite;
}

.clients-gradient {
  background: linear-gradient(90deg, rgba(247, 243, 234, 1) 0%, rgba(255, 253, 227, 0) 13%, rgba(255, 253, 225, 0) 87%, rgba(247, 243, 234, 1) 100%);
}

@media (min-width: 1280px) {
  .marquee-aboutUs {
    animation: marquee-aboutUs 50s linear infinite;
  }
}

@keyframes marquee-aboutUs {
  to {
    transform: translate(calc(-50% - 1rem));
  }
}

@media screen and (max-width: 1740px) {
  .mobile-timeline-container {
    max-width: 18rem !important;
    overflow: hidden !important;
  }

}

.vision-section {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.80) 9.03%, rgba(0, 0, 0, 0.24) 91.86%), url('assets/aboutUs/visionbg.webp'), lightgray 50% / cover no-repeat;
}


.teams-slider .swiper-pagination .swiper-pagination-bullet-active {
  background: #226150 !important;
}

.timeline .swiper-pagination .swiper-pagination-bullet-active,
.timeline .swiper-pagination .swiper-pagination-bullet {
  background: #fff !important;
}

@media (min-width : 1024px) {
  .teams-slider .swiper-pagination {
    display: none !important;
    /* position: absolute !important;
    top: 46%;
    left: 94% !important;
    transform: rotate(90deg) !important;
    height: min-content !important;
    border: 5px solid red !important; */

  }
}