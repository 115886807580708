.custom-marquee {
  flex-shrink: 0;
  animation: scroll-x 40s linear infinite;
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - 2rem));
  }
}

.strength-article:hover {
  transition: box-shadow 0.5s;
  box-shadow: 5px 5px 0px 0px #193C34;
}