.product-card-container {
  background: transparent;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.1));
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.product-card {
  clip-path: polygon(0% 0%, 84% 0%, 100% 14%, 100% 100%, 14% 100%, 0% 84%);
  background-color: white;
}

/* Coming soon section */
@media (min-width: 768px) {
  .coming-soon .img-coming-soon {
    /* animation-name: alternating-image; */
    animation-duration: 1.2s;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    /* -webkit-animation-name: alternating-image; */
    -webkit-animation-duration: 1.2s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-direction: alternate;
    -webkit-animation-iteration-count: infinite;
  }

  /* .coming-soon .img-1 {
    transform-origin: 33% 97%;
  }

  .coming-soon .img-2 {
    transform-origin: 60% 98%;
    animation-delay: 0.2s;
  }

  .coming-soon .img-3 {
    transform-origin: 30% 2%;
    animation-delay: 0.5s;
  }

  .coming-soon .img-4 {
    transform-origin: 5% 5%;
    animation-delay: 0.8s;
  } */
}

@keyframes alternating-image {
  from {
    transform: rotateZ(0);
  }

  to {
    transform: rotateZ(10deg);
  }
}

/* Products */
.products-swiper .swiper {
  padding-bottom: 3.5rem !important;
}

.products-swiper .swiper-pagination-bullet {
  transition: all 1s;
}

.products-swiper .swiper-pagination-bullet-active {
  background: #163E33 !important;
  width: 1.8rem !important;
  border-radius: 0.5em !important;
}