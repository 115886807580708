/* !Hiding scrollbar */
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
}

/* For IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* !Pricing plan */
.pricing-highlighted {
  box-shadow: 5px 5px 0px 0px #FDC20C;
}

.pricing-services {
  grid-template-columns: 0.8fr 1.2fr !important;
}



/* !Handling swiperjs pagination */
.swiper-container .swiper {
  padding-bottom: 3rem !important;
}

.swiper-container .swiper-pagination-bullet-active {
  background-color: #22574d !important;
}

/* !Handling fading slides */
.slide-right .fade-slide-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-right .fade-slide-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.slide-right .fade-slide-exit {
  opacity: 1;
  transform: translateX(0%);
}

.slide-right .fade-slide-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.slide-left .fade-slide-enter {
  opacity: 0;
  transform: translateX(100%);
}

.slide-left .fade-slide-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.slide-left .fade-slide-exit {
  opacity: 1;
  transform: translateX(0%);
}

.slide-left .fade-slide-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

/* !Transition for process blade */
.fade-show-enter {
  opacity: 0;
}

.fade-show-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.fade-show-exit {
  opacity: 1;
}

.fade-show-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

/* cursor-[url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="525" height="525" viewBox="0 0 525 525"><g id="Ellipse_11" data-name="Ellipse 11" fill="none" stroke="%23e2a935" stroke-width="2" stroke-dasharray="10 10"><circle cx="262.5" cy="262.5" r="262.5" stroke="none"/><circle cx="262.5" cy="262.5" r="261.5" fill="none"/></g></svg>'),_pointer] */