.tech-benefit-card {
  clip-path: polygon(10% 0, 100% 0, 100% 70%, 90% 100%, 0 100%, 0 30%);
}

.tech-benefit-card-container {
  filter: drop-shadow(5px 5px 0px #000000) !important;
}

.tech-benefits-swiper .swiper-pagination .swiper-pagination-bullet-active,
.tech-benefits-swiper .swiper-pagination .swiper-pagination-bullet,
.technology-swiper .swiper-pagination .swiper-pagination-bullet-active,
.technology-swiper .swiper-pagination .swiper-pagination-bullet {
  background: #193c34 !important;
}

.tech-title-clipped {
  clip-path: polygon(100% 0%, 100% 70%, 82% 100%, 0 100%, 0 0);
}

.tech-slider-btn {
  box-shadow: -2px 2px 10px 0px #7e7e7e4d !important;
}