.d {
  @apply border-2 border-[red] border-solid;
}

.text-anchor {
  @apply text-base text-dark;
}

.markdown-wrapper h5 {
  @apply font-bold underline pt-8;
}

.markdown-wrapper p {
  @apply py-3.5;
}

.markdown-wrapper a {
  @apply text-blue-600 underline;
}

.markdown-wrapper ol {
  @apply pl-8 list-outside list-decimal
}

.markdown-wrapper ul {
  @apply pl-8 list-outside list-disc;
}

.markdown-wrapper ol li,
.markdown-wrapper ul li {
  @apply pb-3;
}

.cta-section {
  background-size: 60%;
}

@media (min-width: 1600px) {
  .cta-section {
    background-size: 55%;
  }
}

.marquee {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 100%;
  animation: scroll-x 300s linear infinite;
}

.marquee-reverse {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 100%;
  animation: scroll-x-reverse 320s linear infinite;
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - 2rem));
  }
}

@keyframes scroll-x-reverse {
  from {
    transform: translateX(calc(-100% - 2rem));
  }

  to {
    transform: translateX(0);
  }
}

.text-appear-anim {
  opacity: 0;
  animation-name: text-appear;
  animation-duration: 0.6s;
  animation-delay: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  -webkit-animation-name: text-appear;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-delay: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
}

.text-appear-anim-delayed {
  opacity: 0;
  animation-name: text-appear;
  animation-duration: 0.6s;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  -webkit-animation-name: text-appear;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-delay: 0.4s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
}

@keyframes text-appear {
  from {
    opacity: 0;
    transform: translateY(6rem);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.text-appear-anim-scale-up {
  animation-name: text-appear-anim-scale-up;
  animation-duration: 0.6s;
  animation-delay: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  -webkit-animation-name: text-appear-anim-scale-up;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-delay: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
}

@keyframes text-appear-anim-scale-up {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}