.process-section-desk .swiper-pagination-bullet,
.process-section-mob .swiper-pagination-bullet {
  background: transparent !important;
  border: 1px solid white !important;
  opacity: 1 !important;
}

.process-section-desk .swiper-pagination-bullet {
  padding: 0.25em !important;
  margin-inline: 0.8em !important;
}

.process-section-desk .swiper-pagination-bullet-active,
.process-section-mob .swiper-pagination-bullet-active {
  background: #e8ba3a !important;
  border: transparent !important;
}

.process-section-desk .swiper-pagination-bullet-active {
  padding: 0.4em 0.41em !important;
}

.process-section-desk .swiper-pagination-horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg) translateX(-12px);
  position: absolute;
  top: 50%;
  width: min-content;
  height: min-content;
  right: -1rem !important;
  left: auto;
}

.goals .swiper-pagination-bullet {
  background-color: #193C34 !important;
}